import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value.trim());
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <div className="alert alert-info alert-dismissible fade show" role="alert">
        <strong>Important!</strong> Readysetconnect is not supporting BlueJeans video platform anymore. Please start your meeting through our platform only in case you have Bluejeans URL.
      </div>
      <img style={{ width: "50%", marginBottom: "15px" }} src='https://rscapp-test.azurewebsites.net/theme-rsc/images/ready-set-connect-dashboard.png' />
      <Typography variant="h5" className={classes.gutterBottom}>
        <b>Join Meeting</b>
      </Typography>
      <p>Please fill out below details to start your meeting.</p>
      <form onSubmit={handleSubmit}>
        <div>
          {!hasUsername && (
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend"> <span className="input-group-text"><i className="far fa-user"></i></span> </div>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  className="form-control"
                  placeholder="Enter Your Name*"
                />
                <span className="field-validation-valid" data-valmsg-for="FullName" data-valmsg-replace="true"></span>
              </div>
            </div>
          )}
          {/* <div>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend"> <span className="input-group-text"><i className="far fa-video"></i></span> </div>
                <TextField
                  autoCapitalize="false"
                  id="input-room-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={roomName}
                  onChange={handleRoomNameChange}
                  className="form-control"
                  placeholder="Enter Room Name*"
                  disabled
                  style={{ cursor: "not-allowed" }}
                />
                <span className="field-validation-valid" data-valmsg-for="FullName" data-valmsg-replace="true"></span>
              </div>
            </div>
          </div> */}
        </div>
        <Grid container justifyContent="flex-start">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName}
            className={classes.continueButton}
            style={{ width: "40%" }}
          >
            Join Meeting
          </Button>
        </Grid>
      </form>
    </>
  );
}
